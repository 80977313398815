import React from "react";

function About() {
    return (
        <div>
            <div className="aboutarea__2 sp_top_100">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12" data-aos="fade-up">
                            <div className="about__right__wraper__2">
                                <div className="educationarea__img" data-tilt>
                                    <img loading="lazy" className="aboutarea__2__img__1"
                                         src="images/about/about_1.png"
                                         alt="education"/>
                                    <img loading="lazy" className="aboutarea__2__img__2"
                                         src="images/about/about_2.png"
                                         alt="education"/>
                                    <img loading="lazy" className="aboutarea__2__img__3" src="img/about/about_4.png"
                                         alt="education"/>
                                    <img loading="lazy" className="aboutarea__2__img__4"
                                         src="img/about/about_11.png"
                                         alt="education"/>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12" data-aos="fade-up">

                            <div className="aboutarea__content__wraper">
                                <div className="aboutarea__button">
                                    <div className="default__small__button">আমাদের সম্পর্কে</div>
                                </div>
                                <div className="aboutarea__headding heading__underline">
                                    <h2><span>ফ্রগবিড একাডেমিতে</span>আপনাকে স্বাগতম</h2>
                                </div>
                                <div className="aboutarea__para aboutarea__para__2">
                                    <p>আপনার সন্তানকে ছোটবেলা থেকেই একাডেমিক শিক্ষার পাশাপাশি যদি সৃজনশীল কাজে
                                        সংযুক্ত করা যায়
                                        তাহলে সে একজন মুক্তমনা মানুষ হিসেবে গড়ে ওঠে। শিশুদের কল্পনা শক্তিও প্রখর।
                                        তাই সৃজনশীল
                                        কাজে তাদের আগ্রহও থাকে অনেক বেশি। ড্রয়িং , ক্রাফটিং, কোডিং প্ল্যাটফর্মে
                                        আপনার শিশুর
                                        দক্ষতা উন্নয়নে ফ্রগবিড একাডেমি আছে আপনার সাথে, আধুনিক শিক্ষাকে আরও সহজ
                                        করতে।</p>
                                </div>
                                <div className="aboutarea__list__2">
                                    <ul>
                                        <li>
                                            <i className="icofont-check"></i> আনন্দের ছলে মানসম্মত শিক্ষা নিশ্চিত
                                            করাই আমাদের
                                            লক্ষ্য।
                                        </li>

                                        <li>
                                            <i className="icofont-check"></i> শিক্ষার্থীদের সৃজনশীল, মানসিক,
                                            প্রযুক্তিগত দক্ষতা
                                            বৃদ্ধি।
                                        </li>

                                        <li>
                                            <i className="icofont-check"></i> একাডেমিক ও ক্রিয়েটিভ শিক্ষার দক্ষতা
                                            উন্নয়নে গাইড
                                            লাইন।
                                        </li>

                                        <li>
                                            <i className="icofont-check"></i> বয়স ভিত্তিক ও মানসিকতা দক্ষতা অনুসারে
                                            ক্লাস বিভাজন।
                                        </li>

                                        <li>
                                            <i className="icofont-check"></i> স্কিল ডেভেলপমেন্টের মাধ্যমে আপনার
                                            বাচ্চাকে একধাপ
                                            এগিয়ে রাখা।
                                        </li>
                                    </ul>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default About
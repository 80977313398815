import React from "react";
import Topbar from "../components/Topbar";
import Header from "../components/Header";
import Mobile from "../components/Mobile";
import Footer from "../components/Footer";
import Follow from "../components/Follow";
import {Link} from "react-router-dom";

function CodeCraft(){
    return (
        <div>
            <main className="main_wrapper overflow-hidden">
                <Topbar></Topbar>
                <Header></Header>
                <Mobile></Mobile>
                <div>
                    <div className="theme__shadow__circle"></div>
                    <div className="theme__shadow__circle shadow__right"></div>
                </div>

                <div className="breadcrumbarea breadcrumbarea--2">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-8">
                                <div className="breadcrumb__content__wraper" data-aos="fade-up">
                                    <div className="breadcrumb__inner text-start">
                                        <ul>
                                            <li><Link to="/"> হোম </Link></li>
                                            <li> মিনি মাস্টার</li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="course__details__heading" data-aos="fade-up">
                                    <h3>কোড ক্রাফট </h3>
                                </div>
                                <div className="course__details__price" data-aos="fade-up">
                                    <ul>
                                        <li>
                                            <div className="course__price">
                                                ৳১৬,০০০
                                                <del>/ ৳২০,০০০</del>
                                                ২০% ছাড়
                                            </div>
                                        </li>
                                        <li>
                                            <p> কোর্স ফী </p>

                                        </li>
                                        <li>
                                            <div className="course__details__date">
                                                <i className="icofont-book-alt"></i> সপ্তাহে ২ টি ক্লাশ
                                            </div>
                                        </li>
                                    </ul>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="shape__icon__2">
                        <img loading="lazy" className=" shape__icon__img shape__icon__img__1"
                             src="img/herobanner/herobanner__1.png" alt="photo"/>
                        <img loading="lazy" className=" shape__icon__img shape__icon__img__2"
                             src="img/herobanner/herobanner__2.png" alt="photo"/>
                        <img loading="lazy" className=" shape__icon__img shape__icon__img__3"
                             src="img/herobanner/herobanner__3.png" alt="photo"/>
                        <img loading="lazy" className=" shape__icon__img shape__icon__img__4"
                             src="img/herobanner/herobanner__5.png" alt="photo"/>
                    </div>

                </div>

                <div className="blogarea__2 sp_top_100 sp_bottom_100">
                    <div className="container">
                        <div className="row">

                            <div className="col-xl-8 col-lg-8">

                                <div className="blog__details__content__wraper">
                                    <div className="course__details__paragraph" data-aos="fade-up">
                                        <p>৬ মাস মেয়াদি প্রোগ্রামিং কোর্সে সম্পূর্ণ কোর্সটি মোট ৪ টি মডিউল এবং ৪৮ টি
                                            ক্লাসে ভাগ করা
                                            হয়েছে , যেখানে আপনার সন্তান ৫/৬ টি পূর্ণাঙ্গ প্রজেক্ট বাস্তবে লাইভ করতে
                                            পারবে।
                                        </p>
                                    </div>

                                    <div className="course__details__tab__wrapper" data-aos="fade-up">
                                        <div className="row">
                                            <div className="col-xl-12">
                                                <ul className="nav  course__tap__wrap" id="myTab" role="tablist">

                                                    <li className="nav-item" role="presentation">
                                                        <button className="single__tab__link active"
                                                                data-bs-toggle="tab"
                                                                data-bs-target="#projects__two" type="button"><i
                                                            className="icofont-book-alt"></i> কোর্স কারিকুলাম
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="tab-content tab__content__wrapper" id="myTabContent">
                                            <div className="tab-pane fade  active show" id="projects__two"
                                                 role="tabpanel"
                                                 aria-labelledby="projects__two">
                                                <div className="accordion content__cirriculum__wrap"
                                                     id="accordionExample">
                                                    <div className="accordion-item">
                                                        <h2 className="accordion-header" id="headingOne">
                                                            <button className="accordion-button" type="button"
                                                                    data-bs-toggle="collapse"
                                                                    data-bs-target="#collapseOne" aria-expanded="true"
                                                                    aria-controls="collapseOne">
                                                                মডিউলঃ ১ <span> ১২টি ক্লাশ </span>
                                                            </button>
                                                        </h2>
                                                        <div id="collapseOne"
                                                             className="accordion-collapse collapse show"
                                                             aria-labelledby="headingOne"
                                                             data-bs-parent="#accordionExample">
                                                            <div className="accordion-body">
                                                                <div className="scc__wrap">
                                                                    <div className="scc__info">
                                                                        <h5>Introduction to Web Development</h5>
                                                                    </div>
                                                                </div>
                                                                <div className="scc__wrap">
                                                                    <div className="scc__info">
                                                                        <h5>Basics of HTML : Structure and Syntax</h5>
                                                                    </div>
                                                                </div>
                                                                <div className="scc__wrap">
                                                                    <div className="scc__info">
                                                                        <h5>Elements and Tags</h5>
                                                                    </div>
                                                                </div>
                                                                <div className="scc__wrap">
                                                                    <div className="scc__info">
                                                                        <h5>Images and Multimedia</h5>
                                                                    </div>
                                                                </div>
                                                                <div className="scc__wrap">
                                                                    <div className="scc__info">
                                                                        <h5>Designing Tables for Data Presentation</h5>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="accordion-item">
                                                        <h2 className="accordion-header" id="headingTwo">
                                                            <button className="accordion-button collapsed" type="button"
                                                                    data-bs-toggle="collapse"
                                                                    data-bs-target="#collapseTwo"
                                                                    aria-expanded="false" aria-controls="collapseTwo">
                                                                মডিউলঃ ২ <span>১২ টি ক্লাশ</span>
                                                            </button>
                                                        </h2>
                                                        <div id="collapseTwo" className="accordion-collapse collapse"
                                                             aria-labelledby="headingTwo"
                                                             data-bs-parent="#accordionExample">
                                                            <div className="accordion-body">
                                                                <div className="scc__wrap">
                                                                    <div className="scc__info">
                                                                        <h5>Introduction to CSS</h5>
                                                                    </div>
                                                                </div>
                                                                <div className="scc__wrap">
                                                                    <div className="scc__info">
                                                                        <h5>Inline vs. Internal vs. External
                                                                            Stylesheets</h5>
                                                                    </div>
                                                                </div>
                                                                <div className="scc__wrap">
                                                                    <div className="scc__info">
                                                                        <h5>Styling Boxes and Layouts</h5>
                                                                    </div>
                                                                </div>
                                                                <div className="scc__wrap">
                                                                    <div className="scc__info">
                                                                        <h5>Adding Gradients and Images as
                                                                            Backgrounds</h5>
                                                                    </div>
                                                                </div>
                                                                <div className="scc__wrap">
                                                                    <div className="scc__info">
                                                                        <h5>Responsive Designs</h5>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="accordion-item">
                                                        <h2 className="accordion-header" id="headingThree">
                                                            <button className="accordion-button collapsed" type="button"
                                                                    data-bs-toggle="collapse"
                                                                    data-bs-target="#collapseThree"
                                                                    aria-expanded="false" aria-controls="collapseThree">
                                                                মডিউলঃঃ ৩ <span> ১২ টি ক্লাশ</span>
                                                            </button>
                                                        </h2>
                                                        <div id="collapseThree" className="accordion-collapse collapse"
                                                             aria-labelledby="headingThree"
                                                             data-bs-parent="#accordionExample">
                                                            <div className="accordion-body">
                                                                <div className="scc__wrap">
                                                                    <div className="scc__info">
                                                                        <h5>Introduction to JavaScript</h5>
                                                                    </div>
                                                                </div>
                                                                <div className="scc__wrap">
                                                                    <div className="scc__info">
                                                                        <h5>Conditional Loops and Loop Control</h5>
                                                                    </div>
                                                                </div>
                                                                <div className="scc__wrap">
                                                                    <div className="scc__info">
                                                                        <h5>Functions in JavaScript</h5>
                                                                    </div>
                                                                </div>
                                                                <div className="scc__wrap">
                                                                    <div className="scc__info">
                                                                        <h5>Modifying HTML and CSS Dynamically</h5>
                                                                    </div>
                                                                </div>
                                                                <div className="scc__wrap">
                                                                    <div className="scc__info">
                                                                        <h5>Exploring jQuery for DOM Manipulation</h5>
                                                                    </div>
                                                                </div>
                                                                <div className="scc__wrap">
                                                                    <div className="scc__info">
                                                                        <h5>JavaScript Libraries</h5>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="accordion-item">
                                                        <h2 className="accordion-header" id="headingFour">
                                                            <button className="accordion-button collapsed" type="button"
                                                                    data-bs-toggle="collapse"
                                                                    data-bs-target="#collapseFour"
                                                                    aria-expanded="false" aria-controls="collapseFour">
                                                                মডিউলঃঃ ৪ <span> ১২ টি ক্লাশ</span>
                                                            </button>
                                                        </h2>
                                                        <div id="collapseFour" className="accordion-collapse collapse"
                                                             aria-labelledby="headingFour"
                                                             data-bs-parent="#accordionExample">
                                                            <div className="accordion-body">
                                                                <div className="scc__wrap">
                                                                    <div className="scc__info">
                                                                        <h5>Introduction to Python</h5>
                                                                    </div>
                                                                </div>
                                                                <div className="scc__wrap">
                                                                    <div className="scc__info">
                                                                        <h5>Conditional Statements in Python</h5>
                                                                    </div>
                                                                </div>
                                                                <div className="scc__wrap">
                                                                    <div className="scc__info">
                                                                        <h5>Using Loops for Iteration in Python</h5>
                                                                    </div>
                                                                </div>
                                                                <div className="scc__wrap">
                                                                    <div className="scc__info">
                                                                        <h5>Lists, Dictionaries, and Tuples</h5>
                                                                    </div>
                                                                </div>
                                                                <div className="scc__wrap">
                                                                    <div className="scc__info">
                                                                        <h5>Defining Classes and Objects in Python</h5>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-4">
                                <div className="course__details__sidebar--2">
                                    <div className="event__sidebar__wraper" data-aos="fade-up">
                                        <div className="blogarae__img__2 course__details__img__2" data-aos="fade-up">
                                            <img loading="lazy" src="images/coding_course/coder_2_l.jpg" alt="blog"/>
                                        </div>
                                        <div className="event__price__wraper">

                                            <div className="event__price">
                                                ৳১৬,০০০
                                                <del>/ ৳২০,০০০</del>
                                            </div>
                                            <div className="event__Price__button">
                                                <a href="#">২০% ছাড়</a>
                                            </div>
                                        </div>
                                        <div className="course__summery__lists">
                                            <ul>
                                                <li>
                                                    <div className="course__summery__item">
                                                        <span className="sb_label"> সময়কাল </span><span
                                                        className="sb_content"> ৬ মাস </span>
                                                    </div>
                                                </li>

                                                <li>
                                                    <div className="course__summery__item">
                                                        <span className="sb_label"> ক্লশের সংখ্যা </span><span
                                                        className="sb_content"> ৪৮ টি </span>
                                                    </div>
                                                </li>

                                                <li>
                                                    <div className="course__summery__item">
                                                        <span className="sb_label">কোর্স লেভেল</span><span
                                                        className="sb_content"> ইন্টারমিডিয়েট </span>
                                                    </div>
                                                </li>

                                                <li>
                                                    <div className="course__summery__item">
                                                        <span className="sb_label"> কুইজ </span><span
                                                        className="sb_content"> হ্যাঁ </span>
                                                    </div>
                                                </li>

                                                <li>
                                                    <div className="course__summery__item">
                                                        <span className="sb_label"> সার্টিফিকেট  </span><span
                                                        className="sb_content">হ্যাঁ</span>
                                                    </div>
                                                </li>

                                            </ul>
                                        </div>
                                        <div className="course__summery__button">
                                            <p> যোগাযোগ করুন </p>
                                            <a className="default__button default__button--3" href="tel:+8801729277769"><i
                                                className="icofont-phone"></i> +৮৮০ ১৭২৯ ২৭ ৭৭ ৬৯</a>
                                        </div>
                                    </div>
                                    <Follow></Follow>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer></Footer>
            </main>
        </div>
    )
}

export default CodeCraft
import React from "react";


function Follow() {
    return (
        <div>
            <div className="blogsidebar__content__wraper__2" data-aos="fade-up">

                <h4 className="sidebar__title"> অনুসরন করুন </h4>
                <div className="follow__icon">
                    <ul>
                        <li>
                            <a href="https://www.facebook.com/frogbidacademy" target="_blank"><i
                                className="icofont-facebook"></i></a>
                        </li>

                        <li>
                            <a href="#"><i className="icofont-youtube-play"></i></a>
                        </li>
                        <li>
                            <a href="https://www.instagram.com/frogbid_academy/" target="_blank"><i
                                className="icofont-instagram"></i></a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Follow
import React from "react";
import {Link} from "react-router-dom";

function Breadcrumbar (props){
    return (
        <div>
            <div className="breadcrumbarea" data-aos="fade-up">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="breadcrumb__content__wraper">
                                <div className="breadcrumb__title">
                                    <h2 className="heading">{props.title}</h2>
                                </div>
                                <div className="breadcrumb__inner">
                                    <ul>
                                        <li><Link to="/">হোম</Link></li>
                                        <li>{props.title}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="shape__icon__2">
                    <img loading="lazy" className=" shape__icon__img shape__icon__img__1"
                         src="img/herobanner/herobanner__1.png"
                         alt="photo"/>
                    <img loading="lazy" className=" shape__icon__img shape__icon__img__2"
                         src="img/herobanner/herobanner__2.png"
                         alt="photo"/>
                    <img loading="lazy" className=" shape__icon__img shape__icon__img__3"
                         src="img/herobanner/herobanner__3.png"
                         alt="photo"/>
                    <img loading="lazy" className=" shape__icon__img shape__icon__img__4"
                         src="img/herobanner/herobanner__5.png"
                         alt="photo"/>
                </div>

            </div>
        </div>
    )
}

export default Breadcrumbar
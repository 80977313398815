import React from "react";
import {Link} from "react-router-dom";

function Program() {
    return (
        <div>
            <div className="abouttabarea sp_top_100" id="art_program">
                <div className="container">
                    <div className="row">
                        <div className="section__title text-center" data-aos="fade-up">
                            <div className="section__title__button">
                                <div className="default__small__button">প্রোগ্রাম</div>
                            </div>
                            <div className="section__title__heading heading__underline">
                                <h2> আর্ট এন্ড ক্রাফট <span>প্রোগ্রাম </span>
                                    <br/></h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                            <div className="gridarea__wraper">
                                <div className="gridarea__img">
                                    <Link to="/Mini-Master"><img loading="lazy" src="images/art_course/kid_1.jpg"
                                                               alt="grid"/></Link>
                                    <div className="gridarea__small__button gridarea__small__button__1">

                                    </div>
                                    <div className="gridarea__small__icon">
                                        <Link to="/Mini-Master"><i className="icofont-heart-alt"></i></Link>
                                    </div>

                                </div>
                                <div className="gridarea__content">
                                    <div className="gridarea__list">
                                        <ul>
                                            <li>
                                                <i className="icofont-book-alt"></i> সপ্তাহে ২ দিন
                                            </li>
                                            <li>
                                                <i className="icofont-clock-time"></i> ১ঃ৩০ মিনিট
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="gridarea__heading">
                                        <h3><Link to="/Mini-Master">মিনি মাস্টার </Link></h3>
                                        <span className="mega__menu__label">৪-৬ বছর বয়সীদের জন্য</span>
                                    </div>
                                    <div className="gridarea__price">
                                        ৳১,২০০
                                        <del>/ ৳১,৫০০</del>
                                        <span> প্রতি মাসে </span>
                                    </div>
                                    <div className="gridarea__bottom">
                                        <Link to="/Mini-Master">
                                            <div className="gridarea__small__img">
                                                <div
                                                    className="gridarea__small__content flex align-items-center justify-content-center">
                                                    <h6> ক্লাশে সর্বচ্চ ১২-১৪ জন।</h6>
                                                    <div className="row text-center">
                                                        <div className="col-12">
                                                            <Link className="default__button_new mt-3"
                                                               to="/Mini-Master">বিস্তারিত</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                            <div className="gridarea__wraper">
                                <div className="gridarea__img">
                                    <Link to="/Junior-Vision"><img loading="lazy" src="images/art_course/kid_2.jpg"
                                                                 alt="grid"/></Link>
                                    <div className="gridarea__small__button gridarea__small__button__1">

                                    </div>
                                    <div className="gridarea__small__icon">
                                        <Link to="/Junior-Vision"><i className="icofont-heart-alt"></i></Link>
                                    </div>

                                </div>
                                <div className="gridarea__content">
                                    <div className="gridarea__list">
                                        <ul>
                                            <li>
                                                <i className="icofont-book-alt"></i> সপ্তাহে ২ দিন
                                            </li>
                                            <li>
                                                <i className="icofont-clock-time"></i> ১ঃ৩০ মিনিট
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="gridarea__heading">
                                        <h3><Link to="/Junior-Vision">জুনিয়র ভিশন </Link></h3>
                                        <span className="mega__menu__label">৭-১২ বছর বয়সীদের জন্য</span>
                                    </div>
                                    <div className="gridarea__price">
                                        ৳১,২০০
                                        <del>/ ৳১,৫০০</del>
                                        <span> প্রতি মাসে </span>

                                    </div>
                                    <div className="gridarea__bottom">
                                        <Link to="/Junior-Vision">
                                            <div className="gridarea__small__img">
                                                <div
                                                    className="gridarea__small__content flex align-items-center justify-content-center">
                                                    <h6> প্রতি ক্লাশে সর্বচ্চ ১২ জন।</h6>
                                                    <div className="row text-center">
                                                        <div className="col-12">
                                                            <Link className="default__button_new mt-3"
                                                               to="/Junior-Vision">বিস্তারিত</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                            <div className="gridarea__wraper">
                                <div className="gridarea__img">
                                    <Link to="/Creative-Youth"><img loading="lazy" src="images/art_course/kid_3.jpg"
                                                                  alt="grid"/></Link>
                                    <div className="gridarea__small__button gridarea__small__button__1">

                                    </div>
                                    <div className="gridarea__small__icon">
                                        <Link to="/Creative-Youth"><i className="icofont-heart-alt"></i></Link>
                                    </div>

                                </div>
                                <div className="gridarea__content">
                                    <div className="gridarea__list">
                                        <ul>
                                            <li>
                                                <i className="icofont-book-alt"></i> সপ্তাহে ২ দিন
                                            </li>
                                            <li>
                                                <i className="icofont-clock-time"></i> ১ঃ৩০ মিনিট
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="gridarea__heading">
                                        <h3><Link to="/Creative-Youth">ক্রিয়েটিভ ইয়ুথ </Link></h3>
                                        <span className="mega__menu__label">১৩-১৬ বছর বয়সীদের জন্য</span>
                                    </div>
                                    <div className="gridarea__price">
                                        ৳১,২০০
                                        <del>/ ৳১,৫০০</del>
                                        <span> প্রতি মাসে </span>

                                    </div>
                                    <div className="gridarea__bottom">
                                        <Link to="/Creative-Youth">
                                            <div className="gridarea__small__img">
                                                <div
                                                    className="gridarea__small__content flex align-items-center justify-content-center">
                                                    <h6> প্রতি ক্লাশে সর্বচ্চ ১২ জন।</h6>
                                                    <div className="row text-center">
                                                        <div className="col-12">
                                                            <Link className="default__button_new mt-3"
                                                               to="/Creative-Youth">বিস্তারিত</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                            <div className="gridarea__wraper">
                                <div className="gridarea__img">
                                    <Link to="/Studio-Art"><img loading="lazy" src="images/art_course/kid_4.jpg"
                                                              alt="grid"/></Link>
                                    <div className="gridarea__small__button gridarea__small__button__1">

                                    </div>
                                    <div className="gridarea__small__icon">
                                        <Link to="/Studio-Art"><i className="icofont-heart-alt"></i></Link>
                                    </div>

                                </div>
                                <div className="gridarea__content">
                                    <div className="gridarea__list">
                                        <ul>
                                            <li>
                                                <i className="icofont-book-alt"></i> সপ্তাহে ২ দিন
                                            </li>
                                            <li>
                                                <i className="icofont-clock-time"></i> ১ঃ৩০ মিনিট
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="gridarea__heading">
                                        <h3><Link to="/Studio-Art">ষ্টুডিও আর্ট</Link></h3>
                                        <span className="mega__menu__label">১৬+ বছর বয়সীদের জন্য</span>
                                    </div>
                                    <div className="gridarea__price">
                                        ৳১,২০০
                                        <del>/ ৳১,৫০০</del>
                                        <span> প্রতি মাসে </span>

                                    </div>
                                    <div className="gridarea__bottom">
                                        <Link to="/Studio-Art">
                                            <div className="gridarea__small__img">
                                                <div
                                                    className="gridarea__small__content flex align-items-center justify-content-center">
                                                    <h6> প্রতি ক্লাশে সর্বচ্চ ১২ জন।</h6>
                                                    <div className="row text-center">
                                                        <div className="col-12">
                                                            <Link className="default__button_new mt-3"
                                                               to="/Studio-Art">বিস্তারিত</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="counterarea sp_bottom_100" >
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12" data-aos="fade-up">
                                <div className="counterarea__text__wraper">
                                    <div className="counter__img">
                                        <img loading="lazy" src="img/counter/counter__1.png" alt="counter"/>
                                    </div>
                                    <div className="counter__content__wraper">
                                        <div className="counter__number">
                                            <span className="counter">27</span>+

                                        </div>
                                        <p> মোট প্রজেক্ট </p>

                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12" data-aos="fade-up">
                                <div className="counterarea__text__wraper">
                                    <div className="counter__img">
                                        <img loading="lazy" src="img/counter/counter__2.png" alt="counter"/>
                                    </div>
                                    <div className="counter__content__wraper">
                                        <div className="counter__number">
                                            <span className="counter">145</span>+

                                        </div>
                                        <p> মোট ছাত্র ছাত্রী </p>

                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12" data-aos="fade-up">
                                <div className="counterarea__text__wraper">
                                    <div className="counter__img">
                                        <img loading="lazy" src="img/counter/counter__3.png" alt="counter"/>
                                    </div>
                                    <div className="counter__content__wraper">
                                        <div className="counter__number">
                                            <span className="counter">10</span>+

                                        </div>
                                        <p> মোট ইন্সট্রাক্টর </p>

                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12" data-aos="fade-up">
                                <div className="counterarea__text__wraper">
                                    <div className="counter__img">
                                        <img loading="lazy" src="img/counter/counter__4.png" alt="counter"/>
                                    </div>
                                    <div className="counter__content__wraper">
                                        <div className="counter__number">
                                            <span className="counter">214</span>+

                                        </div>
                                        <p> বিশ্ব ব্যাপী ছাত্র ছাত্রীর সংখ্যা</p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container" id="coding_program">
                    <div className="row">
                        <div className="section__title text-center" data-aos="fade-up">
                            <div className="section__title__button">
                                <div className="default__small__button">প্রোগ্রাম</div>
                            </div>
                            <div className="section__title__heading heading__underline">
                                <h2> কোডিং <span>প্রোগ্রাম </span>
                                    <br/></h2>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center justify-content-center">
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                            <div className="gridarea__wraper">
                                <div className="gridarea__img">
                                    <Link to="/Code-Star"><img loading="lazy" src="images/coding_course/coder_1.jpg"
                                                             alt="grid"/></Link>
                                    <div className="gridarea__small__button">
                                    </div>
                                    <div className="gridarea__small__icon">
                                        <Link to="/Code-Star"><i className="icofont-heart-alt"></i></Link>
                                    </div>

                                </div>
                                <div className="gridarea__content">
                                    <div className="gridarea__list">
                                        <ul>
                                            <li>
                                                <i className="icofont-book-alt"></i> ২৪ টি ক্লাশ
                                            </li>
                                            <li>
                                                <i className="icofont-clock-time"></i> ১ঃ৩০ মিনিট
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="gridarea__heading">
                                        <h3><Link to="/Code-Star">কোড ষ্টার </Link></h3> <span
                                        className="mega__menu__label">সময়কালঃ ৩ মাস।</span>
                                    </div>
                                    <div className="gridarea__price">
                                        ৳৯,৬০০
                                        <del>/ ৳১২,০০০</del>
                                        <span> কোর্স ফী </span>

                                    </div>
                                    <div className="gridarea__bottom">
                                        <Link to="/Code-Star">
                                            <div className="gridarea__small__img">
                                                <div
                                                    className="gridarea__small__content flex align-items-center justify-content-center">
                                                    <h6> প্রতি ক্লাশে সর্বচ্চ ১২ জন।</h6>
                                                    <div className="row text-center">
                                                        <div className="col-12">
                                                            <Link className="default__button_new mt-3"
                                                               href="/Code-Star">বিস্তারিত</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                            <div className="gridarea__wraper">
                                <div className="gridarea__img">
                                    <Link to="/Code_Craft"><img loading="lazy" src="images/coding_course/coder_2.jpg"
                                                              alt="grid"/></Link>
                                    <div className="gridarea__small__button">
                                    </div>
                                    <div className="gridarea__small__icon">
                                        <Link to="/Code_Craft"><i className="icofont-heart-alt"></i></Link>
                                    </div>

                                </div>
                                <div className="gridarea__content">
                                    <div className="gridarea__list">
                                        <ul>
                                            <li>
                                                <i className="icofont-book-alt"></i> ৪৮ টি ক্লাশ
                                            </li>
                                            <li>
                                                <i className="icofont-clock-time"></i> ১ঃ৩০ মিনিট
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="gridarea__heading">
                                        <h3><Link to="/Code_Craft">কোড ক্র্যাফট </Link></h3> <span
                                        className="mega__menu__label">সময়কালঃ ৬ মাস।</span>
                                    </div>
                                    <div className="gridarea__price">
                                        ৳১৬,০০০
                                        <del>/ ৳২০,০০০</del>
                                        <span> কোর্স ফী </span>

                                    </div>
                                    <div className="gridarea__bottom">
                                        <Link to="/Code_Craft">
                                            <div className="gridarea__small__img">
                                                <div
                                                    className="gridarea__small__content flex align-items-center justify-content-center">
                                                    <h6> প্রতি ক্লাশে সর্বচ্চ ১২ জন।</h6>
                                                    <div className="row text-center">
                                                        <div className="col-12">
                                                            <Link className="default__button_new mt-3"
                                                               to="/Code_Craft">বিস্তারিত</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                            <div className="gridarea__wraper">
                                <div className="gridarea__img">
                                    <Link to="/Code_Master"><img loading="lazy" src="images/coding_course/coder_3.jpg"
                                                               alt="grid"/></Link>
                                    <div className="gridarea__small__button">
                                    </div>
                                    <div className="gridarea__small__icon">
                                        <Link to="/Code_Master"><i className="icofont-heart-alt"></i></Link>
                                    </div>

                                </div>
                                <div className="gridarea__content">
                                    <div className="gridarea__list">
                                        <ul>
                                            <li>
                                                <i className="icofont-book-alt"></i> ৯৬ টি ক্লাশ
                                            </li>
                                            <li>
                                                <i className="icofont-clock-time"></i> ১ঃ৩০ মিনিট
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="gridarea__heading">
                                        <h3><Link to="/Code_Master">কোড মাস্টার </Link></h3> <span
                                        className="mega__menu__label"> কোর্সের সময়কালঃ ১ বছর।</span>
                                    </div>
                                    <div className="gridarea__price">
                                        ৳৩৬,০০০
                                        <del>/ ৳৪৫,০০০</del>
                                        <span> কোর্স ফী </span>

                                    </div>
                                    <div className="gridarea__bottom">
                                        <Link to="/Code_Master">
                                            <div className="gridarea__small__img">
                                                <div
                                                    className="gridarea__small__content flex align-items-center justify-content-center">
                                                    <h6> প্রতি ক্লাশে সর্বচ্চ ১২ জন।</h6>
                                                    <div className="row text-center">
                                                        <div className="col-12">
                                                            <Link className="default__button_new mt-3"
                                                               to="/Code_Master">বিস্তারিত</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="registerarea sp_top_90">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-12" data-aos="fade-up">
                                <div className="registerarea__wraper">
                                    <div className="section__title registerarea__section__title">
                                        <div className="section__title__button">
                                            <div className="default__small__button"> রেজিস্ট্রেশান</div>
                                        </div>
                                        <div className="section__title__heading heading__underline">
                                            <h2>নিয়মিত <span>আপডেট জানতে </span>আমাদের সঙ্গে থাকুন
                                            </h2>
                                        </div>
                                    </div>
                                    <div className="registerarea__content">
                                        <div className="registerarea__video">
                                            <div className="registerarea__para">
                                                <p>আমাদের সর্বশেষ কোর্স আপডেট এবং ফ্রি গাইড লাইন পেতে সাবস্ক্রাইব
                                                    করুন</p>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>


                            <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12" data-aos="fade-up">
                                <div className="registerarea__form">
                                    <div className="registerarea__form__heading">
                                        <h4>মতামত প্রদান করুন</h4>
                                    </div>
                                    <form action="form_submit.php" method="post">
                                        <input className="register__input" type="text" placeholder="নাম *"
                                               name="full_name" required/>
                                        <div className="row">
                                            <div className="col-xl-6">
                                                <input className="register__input" type="text" placeholder="ইমেইল"
                                                       name="email"/>
                                            </div>
                                            <div className="col-xl-6">
                                                <input className="register__input" type="text"
                                                       placeholder="ফোন নাম্বার *" name="phone"
                                                       required/>
                                            </div>
                                        </div>
                                        <textarea className="register__input textarea" name="message" id="#" cols="30"
                                                  rows="10" required> আপনার মতামত *</textarea>
                                        <div className="registerarea__button">
                                            <button className="default__button" name="form_submit" type="submit"> সাবমিট
                                                করুন
                                                <i className="icofont-long-arrow-right"></i>
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="registerarea__img">
                        <img loading="lazy" className="register__1" src="img/register/register__1.png" alt="register"/>
                        <img loading="lazy" className="register__2" src="img/register/register__2.png" alt="register"/>
                        <img loading="lazy" className="register__3" src="img/register/register__3.png" alt="register"/>
                    </div>
                </div>

                <div className="abouttabarea sp_top_100" id="music_program">
                    <div className="container">
                        <div className="row">
                            <div className="section__title text-center" data-aos="fade-up">
                                <div className="section__title__button">
                                    <div className="default__small__button">প্রোগ্রাম</div>
                                </div>
                                <div className="section__title__heading heading__underline">
                                    <h2> মিউজিক <span>প্রোগ্রাম </span>
                                        <br/></h2>
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center justify-content-center">
                            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                                <div className="gridarea__wraper">
                                    <div className="gridarea__img">
                                        <Link to="/Ukulele"><img loading="lazy" src="images/music/music_2.jpg"
                                                               alt="grid"/></Link>
                                        <div className="gridarea__small__button">
                                        </div>
                                        <div className="gridarea__small__icon">
                                            <Link to="/Ukulele"><i className="icofont-heart-alt"></i></Link>
                                        </div>

                                    </div>
                                    <div className="gridarea__content">
                                        <div className="gridarea__list">
                                            <ul>
                                                <li style={{width: '100%'}}>
                                                    <i className="icofont-music-alt"></i> ৩ ও ৬ মাস মেয়াদি
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="gridarea__heading">
                                            <h3><Link to="/Ukulele"> ইউকুলেলে কোর্স</Link></h3>
                                            <span className="mega__menu__label">ভর্তি ফীঃ ৳৫০০</span>
                                        </div>
                                        <div className="gridarea__price">
                                            ৳১,০০০
                                            <del>/ ৳১,২০০</del>
                                            <span> মাসিক বেতন </span>

                                        </div>
                                        <div className="gridarea__bottom">
                                            <Link to="/Ukulele">
                                                <div className="gridarea__small__img">
                                                    <div
                                                        className="gridarea__small__content flex align-items-center justify-content-center">
                                                        <h6> প্রতি ক্লাশে সর্বচ্চ ১২ জন।</h6>
                                                        <div className="row text-center">
                                                            <div className="col-12">
                                                                <Link className="default__button_new mt-3"
                                                                   to="/Ukulele">বিস্তারিত</Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                                <div className="gridarea__wraper">
                                    <div className="gridarea__img">
                                        <Link to="/Keyboard"><img loading="lazy" src="images/music/music_3.jpg"
                                                                alt="grid"/></Link>
                                        <div className="gridarea__small__button">
                                        </div>
                                        <div className="gridarea__small__icon">
                                            <Link to="/Keyboard"><i className="icofont-heart-alt"></i></Link>
                                        </div>

                                    </div>
                                    <div className="gridarea__content">
                                        <div className="gridarea__list">
                                            <ul>
                                                <li style={{width: '100%'}}>
                                                    <i className="icofont-music-alt"></i> ৩ মাস,৬ মাস ও ১ বছর মেয়াদি
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="gridarea__heading">
                                            <h3><Link to="/Keyboard">কীবোর্ড কোর্স</Link></h3>
                                            <span className="mega__menu__label">ভর্তি ফীঃ ৳১,০০০</span>
                                        </div>
                                        <div className="gridarea__price">
                                            ৳১,২০০
                                            <del>/ ৳১,৫০০</del>
                                            <span> মাসিক বেতন </span>

                                        </div>
                                        <div className="gridarea__bottom">
                                            <Link to="/Keyboard">
                                                <div className="gridarea__small__img">
                                                    <div
                                                        className="gridarea__small__content flex align-items-center justify-content-center">
                                                        <h6> প্রতি ক্লাশে সর্বচ্চ ১২ জন।</h6>
                                                        <div className="row text-center">
                                                            <div className="col-12">
                                                                <Link className="default__button_new mt-3"
                                                                   to="/Keyboard">বিস্তারিত</Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                                <div className="gridarea__wraper">
                                    <div className="gridarea__img">
                                        <Link to="/Guitar"><img loading="lazy" src="images/music/music_1.jpg"
                                                              alt="grid"/></Link>
                                        <div className="gridarea__small__button">
                                        </div>
                                        <div className="gridarea__small__icon">
                                            <Link to="/Guitar"><i className="icofont-heart-alt"></i></Link>
                                        </div>

                                    </div>
                                    <div className="gridarea__content">
                                        <div className="gridarea__list">
                                            <ul>
                                                <li style={{width: '100%'}}>
                                                    <i className="icofont-music-alt"></i> ৩ মাস,৬ মাস ও ১ বছর মেয়াদি
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="gridarea__heading">
                                            <h3><Link to="/Guitar">গীটার কোর্স</Link></h3>
                                            <span className="mega__menu__label">ভর্তি ফীঃ ৳৫০০</span>
                                        </div>
                                        <div className="gridarea__price">
                                            ৳১,০০০
                                            <del>/ ৳১,২০০</del>
                                            <span> মাসিক বেতন </span>

                                        </div>
                                        <div className="gridarea__bottom">
                                            <Link to="/Guitar">
                                                <div className="gridarea__small__img">
                                                    <div
                                                        className="gridarea__small__content flex align-items-center justify-content-center">
                                                        <h6> প্রতি ক্লাশে সর্বচ্চ ১২ জন।</h6>
                                                        <div className="row text-center">
                                                            <div className="col-12">
                                                                <Link className="default__button_new mt-3"
                                                                   to="/Guitar">বিস্তারিত</Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Program
import React from "react";
import Topbar from "../components/Topbar";
import Header from "../components/Header";
import Mobile from "../components/Mobile";
import Footer from "../components/Footer";
import {Link} from "react-router-dom";

function Keyboard(){
    return (
        <div>
            <main className="main_wrapper overflow-hidden">
                <Topbar></Topbar>
                <Header></Header>
                <Mobile></Mobile>
                <div>
                    <div className="theme__shadow__circle"></div>
                    <div className="theme__shadow__circle shadow__right"></div>
                </div>
                <div className="breadcrumbarea breadcrumbarea--2">

                    <div className="container">
                        <div className="row">
                            <div className="col-xl-8">
                                <div className="breadcrumb__content__wraper" data-aos="fade-up">
                                    <div className="breadcrumb__inner text-start">
                                        <ul>
                                            <li><Link to="/"> হোম </Link></li>
                                            <li> কীবোর্ড কোর্স</li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="course__details__top--2">
                                    <div className="course__details__heading" data-aos="fade-up">
                                        <h3> কীবোর্ড কোর্স </h3>
                                    </div>
                                    <div className="course__details__price" data-aos="fade-up">
                                        <ul>
                                            <li>
                                                <div className="course__details__date">
                                                    <i className="icofont-book-alt"></i> সপ্তাহে ১ টি ক্লাশ
                                                </div>

                                            </li>

                                            <li>
                                                <div className="course__date">
                                                    <i className="icofont-clock-time"></i> প্রতিটি ক্লাশ <span> ০১ঃ৩০ মিনিট </span>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="shape__icon__2">
                        <img loading="lazy" className=" shape__icon__img shape__icon__img__1"
                             src="img/herobanner/herobanner__1.png" alt="photo"/>
                        <img loading="lazy" className=" shape__icon__img shape__icon__img__2"
                             src="img/herobanner/herobanner__2.png" alt="photo"/>
                        <img loading="lazy" className=" shape__icon__img shape__icon__img__3"
                             src="img/herobanner/herobanner__3.png" alt="photo"/>
                        <img loading="lazy" className=" shape__icon__img shape__icon__img__4"
                             src="img/herobanner/herobanner__5.png" alt="photo"/>
                    </div>

                </div>
                <div className="blogarea__2 sp_top_50 sp_bottom_100">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12">
                                <div className="row">
                                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12" data-aos="fade-up">
                                        <div className="pricingarea__content__wraper">
                                            <div className="pricingarea__heading">
                                                <div className="pricingarea__plan__type">
                                                    <h6>৩ মাস মেয়াদি কোর্স</h6>
                                                </div>

                                                <div className="pricingarea__number">
                                                    <h1><span className="price__currency">৳</span>১,২০০<span
                                                        className="price__durition">/ মাস</span></h1>
                                                </div>
                                            </div>
                                            <div className="pricingarea__list">
                                                <ul>
                                                    <li>
                                                        <i className="icofont-check"></i> কীবোর্ডের পরিচিতি
                                                    </li>
                                                    <li>
                                                        <i className="icofont-check"></i> মৌলিক সঙ্গীত তত্ত্ব
                                                    </li>
                                                    <li>
                                                        <i className="icofont-check"></i> সরল স্কেল ও সুর
                                                    </li>
                                                    <li>
                                                        <i className="icofont-check"></i> সুরের অগ্রগতি
                                                    </li>
                                                    <li>
                                                        <i className="icofont-check"></i> সরল সুর ও গান
                                                    </li>
                                                    <li>
                                                        <i className="icofont-check"></i> মৌলিক সাইট রিডিং
                                                    </li>
                                                    <li>
                                                        <i className="icofont-check"></i> ডাইনামিক্সের সাথে খেলা
                                                    </li>
                                                    <li>
                                                        <i className="icofont-check"></i> গান পরিবেশন
                                                    </li>
                                                    <li>
                                                        <i className="icofont-check"></i> দক্ষতার মূল্যায়ন
                                                    </li>

                                                </ul>
                                            </div>
                                            <div className="pricingarea__button">
                                                <Link className="default__button" to="/Contact">যোগাযোগ</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12" data-aos="fade-up">
                                        <div className="pricingarea__content__wraper">
                                            <div className="pricingarea__heading">
                                                <div className="pricingarea__plan__type">
                                                    <h6>৬ মাস মেয়াদি কোর্স</h6>
                                                </div>

                                                <div className="pricingarea__number">
                                                    <h1><span className="price__currency">৳</span>১,২০০<span
                                                        className="price__durition">/ মাস</span></h1>
                                                </div>
                                            </div>
                                            <div className="pricingarea__list">
                                                <ul>
                                                    <li>
                                                        <i className="icofont-check"></i> বিস্তারিত সুর
                                                    </li>
                                                    <li>
                                                        <i className="icofont-check"></i> মধ্যবর্তী স্কেল ও আরপেজিও
                                                    </li>
                                                    <li>
                                                        <i className="icofont-check"></i> রিদম এবং মিটার
                                                    </li>
                                                    <li>
                                                        <i className="icofont-check"></i> উন্নত সুরের অগ্রগতি
                                                    </li>
                                                    <li>
                                                        <i className="icofont-check"></i> ইম্প্রোভাইজেশনের মৌলিক বিষয়
                                                    </li>
                                                    <li>
                                                        <i className="icofont-check"></i> মমধ্যবর্তী সাইট রিডিং
                                                    </li>
                                                    <li>
                                                        <i className="icofont-check"></i> গান লেখা ও সঙ্গীত সাজানো
                                                    </li>
                                                    <li>
                                                        <i className="icofont-check"></i> প্রদর্শন কৌশল
                                                    </li>
                                                    <li>
                                                        <i className="icofont-check"></i> দক্ষতার মূল্যায়ন
                                                    </li>

                                                </ul>
                                            </div>
                                            <div className="pricingarea__button">
                                                <Link className="default__button" href="/Contact">যোগাযোগ</Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12" data-aos="fade-up">
                                        <div className="pricingarea__content__wraper">
                                            <div className="pricingarea__heading">
                                                <div className="pricingarea__plan__type">
                                                    <h6>১ বছর মেয়াদি কোর্স</h6>
                                                </div>

                                                <div className="pricingarea__number">
                                                    <h1><span className="price__currency">৳</span>১,২০০<span
                                                        className="price__durition">/ মাস</span></h1>
                                                </div>
                                            </div>
                                            <div className="pricingarea__list">
                                                <ul>
                                                    <li>
                                                        <i className="icofont-check"></i> উন্নত স্কেল ও মোড
                                                    </li>
                                                    <li>
                                                        <i className="icofont-check"></i> জটিল সুর ও ভয়সিং
                                                    </li>
                                                    <li>
                                                        <i className="icofont-check"></i> উন্নত দৃষ্টি পড়া
                                                    </li>
                                                    <li>
                                                        <i className="icofont-check"></i> শাস্ত্রীয় রেপারটরি
                                                    </li>
                                                    <li>
                                                        <i className="icofont-check"></i> জ্যাজ ও ব্লুজ
                                                    </li>
                                                    <li>
                                                        <i className="icofont-check"></i> পপ ও সমকালীন শৈলী
                                                    </li>
                                                    <li>
                                                        <i className="icofont-check"></i> উন্নত সুর রচনা
                                                    </li>
                                                    <li>
                                                        <i className="icofont-check"></i> কীবোর্ডের জন্য সঙ্গীত সাজানো
                                                    </li>
                                                    <li>
                                                        <i className="icofont-check"></i> রেকর্ডিং কৌশল
                                                    </li>
                                                    <li>
                                                        <i className="icofont-check"></i> মাস্টার ক্লাস
                                                    </li>
                                                    <li>
                                                        <i className="icofont-check"></i> প্রদর্শন প্রস্তুতি
                                                    </li>
                                                    <li>
                                                        <i className="icofont-check"></i> চূড়ান্ত প্রদর্শনী
                                                    </li>

                                                </ul>
                                            </div>
                                            <div className="pricingarea__button">
                                                <a className="default__button" href="Contact">যোগাযোগ</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer></Footer>
            </main>
        </div>
    )
}

export default Keyboard
import React from "react";
import Topbar from "../components/Topbar";
import Header from "../components/Header";
import Mobile from "../components/Mobile";
import Footer from "../components/Footer";
import Follow from "../components/Follow";
import {Link} from "react-router-dom";

function CreativeYouth(){
    return (
        <div>
            <main className="main_wrapper overflow-hidden">
                <Topbar></Topbar>
                <Header></Header>
                <Mobile></Mobile>
                <div>
                    <div className="theme__shadow__circle"></div>
                    <div className="theme__shadow__circle shadow__right"></div>
                </div>

                <div className="breadcrumbarea breadcrumbarea--2">

                    <div className="container">
                        <div className="row">
                            <div className="col-xl-8">
                                <div className="breadcrumb__content__wraper" data-aos="fade-up">
                                    <div className="breadcrumb__inner text-start">
                                        <ul>
                                            <li><Link to="/"> হোম </Link></li>
                                            <li> ক্রিয়েটিভ ইয়ুথ</li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="course__details__top--2">
                                    <div className="course__details__heading" data-aos="fade-up">
                                        <h3> ক্রিয়েটিভ ইয়ুথ (১৩ থেকে ১৬ বছর বয়সীদের জন্য )</h3>
                                    </div>
                                    <div className="course__details__price" data-aos="fade-up">
                                        <ul>
                                            <li>
                                                <div className="course__details__date">
                                                    <i className="icofont-book-alt"></i> সপ্তাহে ২ টি ক্লাশ
                                                </div>

                                            </li>

                                            <li>
                                                <div className="course__date">
                                                    <i className="icofont-clock-time"></i> প্রতিটি ক্লাশ <span> ০১ঃ৩০ মিনিট </span>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="shape__icon__2">
                        <img loading="lazy" className=" shape__icon__img shape__icon__img__1"
                             src="img/herobanner/herobanner__1.png" alt="photo"/>
                        <img loading="lazy" className=" shape__icon__img shape__icon__img__2"
                             src="img/herobanner/herobanner__2.png" alt="photo"/>
                        <img loading="lazy" className=" shape__icon__img shape__icon__img__3"
                             src="img/herobanner/herobanner__3.png" alt="photo"/>
                        <img loading="lazy" className=" shape__icon__img shape__icon__img__4"
                             src="img/herobanner/herobanner__5.png" alt="photo"/>
                    </div>

                </div>

                <div className="blogarea__2 sp_top_50 sp_bottom_100">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-8 col-lg-8">
                                <div className="row">
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12" data-aos="fade-up">
                                        <div className="pricingarea__content__wraper">
                                            <div className="pricingarea__heading">
                                                <div className="pricingarea__plan__type">
                                                    <h6>৬ মাস মেয়াদি কোর্স</h6>
                                                </div>

                                                <div className="pricingarea__number">
                                                    <h1><span className="price__currency">৳</span>১,২০০<span
                                                        className="price__durition">/ মাস</span></h1>
                                                </div>
                                            </div>
                                            <div className="pricingarea__list">
                                                <ul>
                                                    <li>
                                                        <i className="icofont-check"></i> আলো ছায়ার মাধ্যমে লাইন ড্রইং
                                                    </li>
                                                    <li>
                                                        <i className="icofont-check"></i> পেন্সিল স্কেচ
                                                    </li>
                                                    <li>
                                                        <i className="icofont-check"></i> স্টিল লাইফ
                                                    </li>
                                                    <li>
                                                        <i className="icofont-check"></i> জল রং
                                                    </li>
                                                    <li>
                                                        <i className="icofont-close close__button"></i> আউটডোর
                                                        ল্যান্ডস্ক্যাপ
                                                    </li>
                                                    <li>
                                                        <i className="icofont-close close__button"></i> এক্রিলিক
                                                        পেইন্টিং
                                                    </li>
                                                    <li>
                                                        <i className="icofont-close close__button"></i> অয়েল পেইন্টিং
                                                        ক্যানভাসে
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="pricingarea__button">
                                                <Link className="default__button" to="/Contact">যোগাযোগ</Link>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12" data-aos="fade-up">
                                        <div className="pricingarea__content__wraper">
                                            <div className="pricingarea__heading">
                                                <div className="pricingarea__plan__type">
                                                    <h6>১ বছর মেয়াদি কোর্স</h6>
                                                </div>

                                                <div className="pricingarea__number">
                                                    <h1><span className="price__currency">৳</span>১,২০০<span
                                                        className="price__durition">/ মাস</span></h1>
                                                </div>
                                            </div>
                                            <div className="pricingarea__list">
                                                <ul>
                                                    <li>
                                                        <i className="icofont-check"></i> আলো ছায়ার মাধ্যমে লাইন ড্রইং
                                                    </li>
                                                    <li>
                                                        <i className="icofont-check"></i> পেন্সিল স্কেচ
                                                    </li>
                                                    <li>
                                                        <i className="icofont-check"></i> স্টিল লাইফ
                                                    </li>
                                                    <li>
                                                        <i className="icofont-check"></i> জল রং
                                                    </li>
                                                    <li>
                                                        <i className="icofont-check"></i> আউটডোর ল্যান্ডস্ক্যাপ
                                                    </li>
                                                    <li>
                                                        <i className="icofont-check"></i> এক্রিলিক পেইন্টিং
                                                    </li>
                                                    <li>
                                                        <i className="icofont-check"></i> অয়েল পেইন্টিং ক্যানভাসে
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="pricingarea__button">
                                                <Link className="default__button" to="/Contact">যোগাযোগ</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4">
                                <div className="course__details__sidebar--2">
                                    <div className="event__sidebar__wraper" data-aos="fade-up">
                                        <div className="blogarae__img__2 course__details__img__2" data-aos="fade-up">
                                            <img loading="lazy" src="images/art_course/3.jpg" alt="blog"/>
                                        </div>

                                        <div className="event__price__wraper">

                                            <div className="event__price">
                                                ৳১,২০০ <del>/ ৳১,৫০০</del>
                                            </div>
                                            <div className="event__Price__button">
                                                <a href="#">২০% ছাড়</a>
                                            </div>
                                        </div>

                                        <div className="course__summery__lists">
                                            <ul>

                                                <li>
                                                    <div className="course__summery__item">
                                                        <span className="sb_label"> সময়কাল </span><span
                                                        className="sb_content"> ৬ মাস / ১ বছর </span>
                                                    </div>
                                                </li>

                                                <li>
                                                    <div className="course__summery__item">
                                                        <span className="sb_label"> ক্লশের সংখ্যা </span><span
                                                        className="sb_content"> ৪৮ টি / ৯৬ টি </span>
                                                    </div>
                                                </li>

                                                <li>
                                                    <div className="course__summery__item">
                                                        <span className="sb_label"> সার্টিফিকেট  </span><span
                                                        className="sb_content">হ্যাঁ</span>
                                                    </div>
                                                </li>

                                            </ul>
                                        </div>
                                        <div className="course__summery__button">
                                            <p>যোগাযোগ করুন</p>
                                            <a className="default__button default__button--3" href="tel:+8801729277769"><i
                                                className="icofont-phone"></i> +৮৮০ ১৭২৯ ২৭ ৭৭ ৬৯</a>
                                        </div>


                                    </div>


                                    <Follow></Follow>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer></Footer>
            </main>
        </div>
    )
}

export default CreativeYouth
import React from "react";

function Topbar () {
    return (
        <div>
            <div className="topbararea">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6">
                            <div className="topbar__left">
                                <ul>
                                    <li>
                                        ফোন করুন : <a href="callto:+8801729277769" style={{ color: 'white' }}>+৮৮০ ১৭২৯ ২৭ ৭৭
                                        ৬৯</a>
                                    </li>
                                    <li>
                                        - ইমেইল করুন : <a href="mailto:contact@frogbidacademy.com"
                                                          style={{ color: 'white' }}>contact@frogbidacademy.com</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6">
                            <div className="topbar__right">
                                <div className="topbar__icon">
                                    <i className="icofont-location-pin"></i>
                                </div>
                                <div className="topbar__text">
                                    <p>১৬ কে ডি এ এভিনিউ ময়লাপোতা, খুলনা - ৯১০০</p>
                                </div>
                                <div className="topbar__list">
                                    <ul>
                                        <li>
                                            <a href="https://www.facebook.com/frogbidacademy" target="_blank"><i
                                                className="icofont-facebook"></i></a>
                                        </li>
                                        <li>
                                            <a href="https://www.instagram.com/frogbid_academy/" target="_blank"><i
                                                className="icofont-instagram"></i></a>
                                        </li>
                                        <li>
                                            <a href="#"><i className="icofont-youtube-play"></i></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Topbar
import React from "react";
import {Link} from "react-router-dom";

function Mobile() {
    return (<div>
            <div className="mobile-off-canvas-active">
                <a className="mobile-aside-close"><i className="icofont  icofont-close-line"></i></a>
                <div className="header-mobile-aside-wrap">
                    <div className="mobile-search">
                    </div>
                    <div className="mobile-menu-wrap headerarea">

                        <div className="mobile-navigation">

                            <nav>
                                <ul className="mobile-menu">
                                    <li><Link to="/">হোম</Link></li>
                                    <li><Link to="/about">আমাদের সম্পর্কে</Link></li>


                                    <li className="menu-item-has-children "><a href="#">আমাদের কোর্সসমূহ</a>
                                        <ul className="dropdown">
                                            <li className="menu-item-has-children">
                                                <a href="#art_program">আর্ট কোর্সসমূহঃ</a>

                                                <ul className="dropdown">
                                                    <li><Link to="/Mini-Master">মিনি মাস্টার<span
                                                        className="mega__menu__label">৪-৬ বছর</span></Link>
                                                    </li>
                                                    <li><Link to="/Junior-Vision">জুনিয়র ভিশন<span
                                                        className="mega__menu__label">৭-১২ বছর</span></Link>
                                                    </li>
                                                    <li><Link to="/Creative-Youth">ক্রিয়েটিভ ইয়ুথ<span
                                                        className="mega__menu__label">১৩-১৬ বছর</span></Link>
                                                    </li>
                                                    <li><Link to="/Studio-Art">ষ্টুডিও আর্ট<span
                                                        className="mega__menu__label">১৬ বছর+</span></Link>
                                                    </li>
                                                </ul>
                                            </li>

                                            <li className="menu-item-has-children">
                                                <a href="#coding_program">কোডিং কোর্সসমূহঃ</a>
                                                <ul className="dropdown">
                                                    <li><Link to="/Code-Star">কোড ষ্টার</Link>
                                                    </li>
                                                    <li><Link to="/Code_Craft">কোড ক্র্যাফট</Link>
                                                    </li>
                                                    <li><Link to="/Code_Master">কোড মাস্টার</Link>
                                                    </li>
                                                </ul>
                                            </li>

                                            <li className="menu-item-has-children">
                                                <a href="#music_program">মিউজিক কোর্সসমূহঃ</a>
                                                <ul className="dropdown">
                                                    <li><Link to="/Ukulele">ইউকুলেলে কোর্স</Link>
                                                    </li>
                                                    <li><Link to="/Keyboard">কীবোর্ড কোর্স</Link>
                                                    </li>
                                                    <li><Link to="/Guitar">গীটার কোর্স</Link>
                                                    </li>
                                                </ul>
                                            </li>

                                            <li className="menu-item-has-children">
                                                <div className="mega__menu__img">
                                                    <a href="#"><img loading="lazy" src="images/menu_banner.png"
                                                                     alt="Mega Menu"/></a>
                                                </div>
                                            </li>
                                        </ul>
                                    </li>
                                    <li><Link to="/Gallery">গ্যালারি</Link></li>
                                    <li><Link to="/Contact">যোগাযোগ</Link></li>

                                </ul>
                            </nav>

                        </div>

                    </div>
                    <div className="mobile-social-wrap">
                        <a className="facebook" href="https://www.facebook.com/frogbidacademy" target="_blank"><i
                            className="icofont icofont-facebook"></i></a>
                        <a className="instagram" href="https://www.instagram.com/frogbid_academy/" target="_blank"><i
                            className="icofont icofont-instagram"></i></a>
                        <a className="google" href="#"><i className="icofont icofont-youtube-play"></i></a>
                    </div>
                </div>
            </div>
        </div>)
}

export default Mobile
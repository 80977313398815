import React from "react";
import {Link} from "react-router-dom";

function Footer() {
    return (
        <div>
            <div className="footerarea footerarea__image"
                 style={{backgroundImage:'url(\'images/footer_bg.png\')',backgroundRepeat:'repeat',backgroundSize:'cover', backgroundPosition:'center'}}>
                <div className="container">
                    <div className="footerarea__newsletter__wraper">
                        <div className="row">
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12" data-aos="fade-up">
                                <div className="footerarea__text">
                                    <h3>নিয়মিত <span>আপডেট জানতে</span> আমাদের সঙ্গে থাকুন</h3>
                                    <p>আমাদের সর্বশেষ কোর্স আপডেট এবং ফ্রি গাইড লাইন পেতে সাবস্ক্রাইব করুন</p>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12" data-aos="fade-up">
                                <div className="footerarea__newsletter">
                                    <div className="footerarea__newsletter__input">
                                        <form action="form_submit.php" method="post">
                                            <input type="email" placeholder="ইমেইল প্রদান করুন" name="email" required/>
                                            <div className="footerarea__newsletter__button">
                                                <button type="submit" name="subscription_data"
                                                        className="subscribe__btn"> সাবস্ক্রাইব করুন
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="footerarea__wrapper footerarea__wrapper__2">
                        <div className="row">
                            <div className="col-xl-6 col-lg-4 col-md-6 col-sm-12" data-aos="fade-up">
                                <div className="footerarea__inner footerarea__about__us">
                                    <div className="footerarea__heading">
                                        <h3> আমাদের সম্পর্কে </h3>
                                    </div>
                                    <div className="footerarea__content">
                                        <p>ফ্রগবিড একাডেমি হল একটি স্বপ্নের প্রতিষ্ঠান, যেখানে শিশুদের জন্য আর্ট এবং
                                            প্রোগ্রামিং
                                            শেখানোর উপর মৌলিক গুরুত্ব দেওয়া হয়। আমাদের উদ্দেশ্য হল প্রযুক্তি এবং
                                            শিল্পের
                                            মাধ্যমে শিশুদের সৃজনশীলতা ও বিচারশীলতা বৃদ্ধি করা।</p>
                                    </div>
                                    <div className="foter__bottom__text">
                                        <div className="footer__bottom__icon">
                                            <i className="icofont-clock-time"></i>
                                        </div>
                                        <div className="footer__bottom__content">
                                            <h6> কার্য দিবসঃ </h6>
                                            <span>শনি - বৃহস্পতি (সকাল ১০ঃ০০ টা থেকে সন্ধ্যা ০৬ঃ০০ টা)</span>
                                            <span> শুক্রবার - বন্ধ </span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-2  col-md-6 col-sm-6" data-aos="fade-up">
                                <div className="footerarea__inner">
                                    <div className="footerarea__heading">
                                        <h3> আর্ট কোর্সসমূহঃ </h3>
                                    </div>
                                    <div className="footerarea__list">
                                        <ul>
                                            <li>
                                                <Link to="/Mini-Master">মিনি মাস্টার</Link>
                                            </li>
                                            <li>
                                                <Link to="/Junior-Vision">জুনিয়ার ভিশন</Link>
                                            </li>
                                            <li>
                                                <Link to="/Creative-Youth">ক্রিয়েটিভ ইয়ুথ</Link>
                                            </li>
                                            <li>
                                                <Link to="/Studio-Art">ষ্টুডিও আর্ট</Link>
                                            </li>
                                        </ul>
                                    </div>


                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6" data-aos="fade-up">
                                <div className="footerarea__inner footerarea__padding__left">
                                    <div className="footerarea__heading">
                                        <h3> কোডিং কোর্সসমূহঃ </h3>
                                    </div>
                                    <div className="footerarea__list">
                                        <ul>
                                            <li>
                                                <Link to="/Code-Star">কোড ষ্টার</Link>
                                            </li>
                                            <li>
                                                <Link to="/Code_Craft">কোড ক্র্যাফট</Link>
                                            </li>
                                            <li>
                                                <Link to="/Code_Master">কোড মাস্টার</Link>
                                            </li>
                                        </ul>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footerarea__copyright__wrapper footerarea__copyright__wrapper__2">
                        <div className="row">
                            <div className="col-xl-3 col-lg-3">
                                <div className="copyright__logo">
                                    <Link to="/"><img loading="lazy" src="img/logo/logo_1.png"
                                                        alt="logo" style={{height:'75px', width:'auto'}}/></Link>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6">
                                <div className="footerarea__copyright__content footerarea__copyright__content__2">
                                    <p>Copyright © <span>2024</span> by ফ্রগবিড একাডেমি. Developed with <a
                                        href="https://frogbid.com/" target="_blank">FrogBid</a></p>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3">
                                <div className="footerarea__icon footerarea__icon__2">
                                    <ul>
                                        <li><a href="https://www.facebook.com/frogbidacademy"><i
                                            className="icofont-facebook"></i></a></li>
                                        <li><a href="https://www.instagram.com/frogbid_academy/"><i
                                            className="icofont-instagram"></i></a></li>
                                        <li><a href="#"><i className="icofont-youtube"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Footer
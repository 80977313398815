import React from "react";
import Topbar from "../components/Topbar";
import Header from "../components/Header";
import Mobile from "../components/Mobile";
import Footer from "../components/Footer";
import Breadcrumbar from "../components/Breadcrumbar";

function Gallery(){
    return (
        <div>
            <main className="main_wrapper overflow-hidden">
                <Topbar></Topbar>
                <Header></Header>
                <Mobile></Mobile>
                <div>
                    <div className="theme__shadow__circle"></div>
                    <div className="theme__shadow__circle shadow__right"></div>
                </div>
                <Breadcrumbar title='গ্যালারি'></Breadcrumbar>
                <div className="gallery pt-3">
                    <div className="container-fluid full__width__padding">
                        <div className="blogsidebar__content__wraper__2">

                            <div className="photo__gallery__img">

                                <div className="single__gallery__img">
                                    <img loading="lazy" src="img/gallery/kids_1.jpg"
                                         alt="photo"/>
                                    <div className="gallery__icon">
                                        <a className="popup__img" href="img/gallery/kids_1.jpg"> <i
                                            className="icofont-eye-alt"></i></a>
                                    </div>
                                </div>
                                <div className="single__gallery__img">
                                    <img loading="lazy" src="img/gallery/kids_2.jpg"
                                         alt="photo"/>
                                    <div className="gallery__icon">
                                        <a className="popup__img" href="img/gallery/kids_2.jpg"> <i
                                            className="icofont-eye-alt"></i></a>
                                    </div>
                                </div>
                                <div className="single__gallery__img">
                                    <img loading="lazy" src="img/gallery/kids_3.jpg"
                                         alt="photo"/>
                                    <div className="gallery__icon">
                                        <a className="popup__img" href="img/gallery/kids_3.jpg"> <i
                                            className="icofont-eye-alt"></i></a>
                                    </div>
                                </div>
                                <div className="single__gallery__img">
                                    <img loading="lazy" src="img/gallery/kids_4.jpg"
                                         alt="photo"/>
                                    <div className="gallery__icon">
                                        <a className="popup__img" href="img/gallery/kids_4.jpg"> <i
                                            className="icofont-eye-alt"></i></a>
                                    </div>
                                </div>
                                <div className="single__gallery__img">
                                    <img loading="lazy" src="img/gallery/kids_5.jpg"
                                         alt="photo"/>
                                    <div className="gallery__icon">
                                        <a className="popup__img" href="img/gallery/kids_5.jpg"> <i
                                            className="icofont-eye-alt"></i></a>
                                    </div>
                                </div>
                                <div className="single__gallery__img">
                                    <img loading="lazy" src="img/gallery/kids_6.jpg"
                                         alt="photo"/>
                                    <div className="gallery__icon">
                                        <a className="popup__img" href="img/gallery/kids_6.jpg"> <i
                                            className="icofont-eye-alt"></i></a>
                                    </div>
                                </div>

                                <div className="single__gallery__img">
                                    <img loading="lazy" src="img/gallery/kids_7.jpg"
                                         alt="photo"/>
                                    <div className="gallery__icon">
                                        <a className="popup__img" href="img/gallery/kids_7.jpg"> <i
                                            className="icofont-eye-alt"></i></a>
                                    </div>
                                </div>
                                <div className="single__gallery__img">
                                    <img loading="lazy" src="img/gallery/kids_8.jpg"
                                         alt="photo"/>
                                    <div className="gallery__icon">
                                        <a className="popup__img" href="img/gallery/kids_8.jpg"> <i
                                            className="icofont-eye-alt"></i></a>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>


                <Footer></Footer>
            </main>
        </div>
    )
}

export default Gallery
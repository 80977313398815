import React from "react";
import {Routes, Route, BrowserRouter} from 'react-router-dom';
import Home from "./pages/home";
import Aboutus from "./pages/Aboutus";
import MiniMaster from "./pages/MiniMaster";
import JuniorVision from "./pages/JuniorVision";
import CreativeYouth from "./pages/CreativeYouth";
import StudioArt from "./pages/StudioArt";
import CodeStar from "./pages/CodeStar";
import CodeCraft from "./pages/CodeCraft";
import CodeMaster from "./pages/CodeMaster";
import Ukulele from "./pages/Ukulele";
import Keyboard from "./pages/Keyboard";
import Guitar from "./pages/Guitar";
import Gallery from "./pages/Gallery";
import Contact from "./pages/Contact";


function App () {
  return (
      <div>
          <BrowserRouter>
              <Routes>
                  <Route path="/" element={<Home/>}></Route>
                  <Route path="/about" element={<Aboutus/>}></Route>
                  <Route path="/Mini-Master" element={<MiniMaster/>}></Route>
                  <Route path="/Junior-Vision" element={<JuniorVision/>}></Route>
                  <Route path="/Creative-Youth" element={<CreativeYouth/>}></Route>
                  <Route path="/Studio-Art" element={<StudioArt/>}></Route>
                  <Route path="/Code-Star" element={<CodeStar/>}></Route>
                  <Route path="/Code-Craft" element={<CodeCraft/>}></Route>
                  <Route path="/Code-Master" element={<CodeMaster/>}></Route>
                  <Route path="/Ukulele" element={<Ukulele/>}></Route>
                  <Route path="/Keyboard" element={<Keyboard/>}></Route>
                  <Route path="/Guitar" element={<Guitar/>}></Route>
                  <Route path="/Gallery" element={<Gallery/>}></Route>
                  <Route path="/Contact" element={<Contact/>}></Route>
              </Routes>
          </BrowserRouter>
      </div>
  )
}

export default App
import React from "react";
import {Link} from "react-router-dom";

function Header() {
    return (
        <div>
            <header>
                <div className="headerarea headerarea__3 header__sticky header__area">
                    <div className="container desktop__menu__wrapper">
                        <div className="row">
                            <div className="col-xl-2 col-lg-2 col-md-6">
                                <div className="headerarea__left">
                                    <div className="headerarea__left__logo">
                                        <Link to="/"><img loading="lazy" src="img/logo/logo_1.png" alt="logo"
                                                             style={{height: '75px', width: 'auto'}}/></Link>
                                    </div>

                                </div>
                            </div>
                            <div className="col-xl-7 col-lg-7 main_menu_wrap">
                                <div className="headerarea__main__menu">
                                    <nav>
                                        <ul>
                                            <li className="mega__menu position-static">
                                                <Link className="headerarea__has__dropdown" to="/">হোম</Link>
                                            </li>

                                            <li className="mega__menu position-static">
                                                <Link className="headerarea__has__dropdown" to="/about">আমাদের
                                                    সম্পর্কে</Link>
                                            </li>

                                            <li className="mega__menu position-static">
                                                <Link className="headerarea__has__dropdown" to="">আমাদের কোর্সসমূহ<i
                                                    className="icofont-rounded-down"></i> </Link>
                                                <div className="headerarea__submenu mega__menu__wrapper">

                                                    <div className="row">
                                                        <div className="col-3 mega__menu__single__wrap">
                                                            <h4 className="mega__menu__title"><a
                                                                href="#art_program">আর্ট কোর্সসমূহঃ</a></h4>
                                                            <ul className="mega__menu__item">
                                                                <li><Link to="/Mini-Master">মিনি মাস্টার<span
                                                                    className="mega__menu__label">৪-৬ বছর</span></Link>
                                                                </li>
                                                                <li><Link to="/Junior-Vision">জুনিয়র ভিশন<span
                                                                    className="mega__menu__label">৭-১২ বছর</span></Link>
                                                                </li>
                                                                <li><Link to="/Creative-Youth">ক্রিয়েটিভ ইয়ুথ<span
                                                                    className="mega__menu__label">১৩-১৬ বছর</span></Link>
                                                                </li>
                                                                <li><Link to="/Studio-Art">ষ্টুডিও আর্ট<span
                                                                    className="mega__menu__label">১৬ বছর+</span></Link>
                                                                </li>

                                                            </ul>
                                                        </div>
                                                        <div className="col-3 mega__menu__single__wrap">
                                                            <h4 className="mega__menu__title"><a
                                                                href="#coding_program">কোডিং কোর্সসমূহঃ <span
                                                                className="mega__menu__label"> ১০ থেকে ১৮ বছর বয়সিীদের জন্য</span></a>
                                                            </h4>
                                                            <ul className="mega__menu__item">
                                                                <li><Link to="/Code-Star">কোড ষ্টার<span
                                                                    className="mega__menu__label">সময়কালঃ ৩ মাস</span></Link>
                                                                </li>
                                                                <li><Link to="/Code-Craft">কোড ক্র্যাফট<span
                                                                    className="mega__menu__label">সময়কালঃ ৬ মাস</span></Link>
                                                                </li>
                                                                <li><Link to="/Code-Master">কোড মাস্টার<span
                                                                    className="mega__menu__label">সময়কালঃ ১ বছর</span></Link>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className="col-3 mega__menu__single__wrap">
                                                            <h4 className="mega__menu__title"><a
                                                                href="#music_program">মিউজিক কোর্সসমূহঃ </a>
                                                            </h4>
                                                            <ul className="mega__menu__item">
                                                                <li><Link to="/Ukulele">ইউকুলেলে কোর্স<span
                                                                    className="mega__menu__label"> ৩ মাস ও ৬ মাস</span></Link>
                                                                </li>
                                                                <li><Link to="/Keyboard">কীবোর্ড কোর্স<span
                                                                    className="mega__menu__label">৩ মাস,৬ মাস ও ১ বছর</span></Link>
                                                                </li>
                                                                <li><Link to="/Guitar">গীটার কোর্স<span
                                                                    className="mega__menu__label">৩ মাস,৬ মাস ও ১ বছর</span></Link>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className="col-3 mega__menu__single__wrap">
                                                            <div className="mega__menu__img">
                                                                <a href="#"><img loading="lazy"
                                                                                 src="images/menu_banner.png"
                                                                                 alt="Mega Menu"/></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </li>

                                            <li className="mega__menu position-static">
                                                <Link className="headerarea__has__dropdown" to="/Gallery">গ্যালারি</Link>
                                            </li>

                                            <li className="mega__menu position-static">
                                                <Link className="headerarea__has__dropdown" to="/Contact">যোগাযোগ</Link>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-6">
                                <div className="headerarea__right">
                                    <div className="headerarea__login">
                                        <a href="admin/Login"><i className="icofont-user-alt-5"></i></a>
                                    </div>
                                    <div className="headerarea__button">
                                        <a href="Contact">যোগাযোগ</a>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>


                    <div className="container-fluid mob_menu_wrapper">
                        <div className="row align-items-center">
                            <div className="col-6">
                                <div className="mobile-logo">
                                    <a className="logo__dark" href="#"><img loading="lazy" src="img/logo/logo_1.png"
                                                                            alt="logo"/></a>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="header-right-wrap">

                                    <div className="headerarea__right">
                                    </div>

                                    <div className="mobile-off-canvas">
                                        <a className="mobile-aside-button" href="#"><i
                                            className="icofont-navigation-menu"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </div>
    )
}

export default Header
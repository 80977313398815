import React from "react";
import Topbar from "../components/Topbar";
import Header from "../components/Header";
import Mobile from "../components/Mobile";
import Footer from "../components/Footer";
import Follow from "../components/Follow";
import {Link} from "react-router-dom";

function StudioArt(){
    return (
        <div>
            <main className="main_wrapper overflow-hidden">
                <Topbar></Topbar>
                <Header></Header>
                <Mobile></Mobile>
                <div>
                    <div className="theme__shadow__circle"></div>
                    <div className="theme__shadow__circle shadow__right"></div>
                </div>

                <div className="breadcrumbarea breadcrumbarea--2">

                    <div className="container">
                        <div className="row">
                            <div className="col-xl-8">
                                <div className="breadcrumb__content__wraper" data-aos="fade-up">
                                    <div className="breadcrumb__inner text-start">
                                        <ul>
                                            <li><Link to="/"> হোম </Link></li>
                                            <li> ষ্টুডিও আর্ট</li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="course__details__top--2">
                                    <div className="course__details__heading" data-aos="fade-up">
                                        <h3> ষ্টুডিও আর্ট (১৬+ বছর বয়সীদের জন্য )</h3>
                                    </div>
                                    <div className="course__details__price" data-aos="fade-up">
                                        <ul>
                                            <li>
                                                <div className="course__details__date">
                                                    <i className="icofont-book-alt"></i> সপ্তাহে ২ টি ক্লাশ
                                                </div>

                                            </li>

                                            <li>
                                                <div className="course__date">
                                                    <i className="icofont-clock-time"></i> প্রতিটি ক্লাশ <span> ০১ঃ৩০ মিনিট </span>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="shape__icon__2">
                        <img loading="lazy" className=" shape__icon__img shape__icon__img__1"
                             src="img/herobanner/herobanner__1.png" alt="photo"/>
                        <img loading="lazy" className=" shape__icon__img shape__icon__img__2"
                             src="img/herobanner/herobanner__2.png" alt="photo"/>
                        <img loading="lazy" className=" shape__icon__img shape__icon__img__3"
                             src="img/herobanner/herobanner__3.png" alt="photo"/>
                        <img loading="lazy" className=" shape__icon__img shape__icon__img__4"
                             src="img/herobanner/herobanner__5.png" alt="photo"/>
                    </div>

                </div>
                <div className="blogarea__2 sp_top_50 sp_bottom_100">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-8 col-lg-8">
                                <div className="blog__details__content__wraper">
                                    <div className="course__details__tab__wrapper" data-aos="fade-up">
                                        <div className="row">
                                            <div className="col-xl-12">
                                                <ul className="nav  course__tap__wrap" id="myTab" role="tablist">
                                                    <li className="nav-item" role="presentation">
                                                        <button className="single__tab__link active"
                                                                data-bs-toggle="tab" data-bs-target="#projects__one"
                                                                type="button"><i
                                                            className="icofont-paper"></i> কারিকুলাম
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="tab-content tab__content__wrapper active show"
                                             id="myTabContent">
                                            <div className="tab-pane fade active show" id="projects__two"
                                                 role="tabpanel" aria-labelledby="projects__one">
                                                <div className="experence__heading">
                                                    <h5> আমাদের কোর্সটিতে যা থাকছে </h5>
                                                </div>
                                                <div className="experence__description">
                                                    <div className="scc__wrap">
                                                        <div className="scc__info">
                                                            <h5>বেসিক টু অ্যাডভান্স ড্রয়িং</h5>
                                                        </div>
                                                    </div>
                                                    <div className="scc__wrap">
                                                        <div className="scc__info">
                                                            <h5>বেসিক ডিজাইন থিওরি </h5>
                                                        </div>
                                                    </div>
                                                    <div className="scc__wrap">
                                                        <div className="scc__info">
                                                            <h5>অ্যাডভান্স ওয়াটার কালার</h5>
                                                        </div>
                                                    </div>
                                                    <div className="scc__wrap">
                                                        <div className="scc__info">
                                                            <h5>আক্র‍্যালিক পেইন্টিং</h5>
                                                        </div>
                                                    </div>
                                                    <div className="scc__wrap">
                                                        <div className="scc__info">
                                                            <h5>কালার মিক্সিং এন্ড ট্রিটমেন্ট</h5>
                                                        </div>
                                                    </div>
                                                    <div className="scc__wrap">
                                                        <div className="scc__info">
                                                            <h5>স্টিল লাইফ- স্কেচ,ওয়াটার কালার, পেইন্টিং</h5>
                                                        </div>
                                                    </div>
                                                    <div className="scc__wrap">
                                                        <div className="scc__info">
                                                            <h5>আউটডোর ল্যান্ডস্ক্যাপ-স্কেচ, ওয়াটার কালার</h5>
                                                        </div>
                                                    </div>
                                                    <div className="scc__wrap">
                                                        <div className="scc__info">
                                                            <h5>পোর্ট্রেট এন্ড ফিগার ড্রয়িং বেসিক এন্ড স্কেচ</h5>
                                                        </div>
                                                    </div>
                                                    <div className="scc__wrap">
                                                        <div className="scc__info">
                                                            <h5>কালার থিওরি এন্ড কম্পোজিশন অয়েল পেইন্টিং</h5>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4">
                                <div className="course__details__sidebar--2">
                                    <div className="event__sidebar__wraper" data-aos="fade-up">
                                        <div className="blogarae__img__2 course__details__img__2" data-aos="fade-up">
                                            <img loading="lazy" src="images/art_course/4.jpg" alt="blog"/>
                                        </div>

                                        <div className="event__price__wraper">

                                            <div className="event__price">
                                                ৳১,২০০ <del>/ ৳১,৫০০</del>
                                            </div>
                                            <div className="event__Price__button">
                                                <a href="#">২০% ছাড়</a>
                                            </div>
                                        </div>

                                        <div className="course__summery__lists">
                                            <ul>

                                                <li>
                                                    <div className="course__summery__item">
                                                        <span className="sb_label"> সার্টিফিকেট  </span><span
                                                        className="sb_content">হ্যাঁ</span>
                                                    </div>
                                                </li>

                                            </ul>
                                        </div>
                                        <div className="course__summery__button">
                                            <p>যোগাযোগ করুন</p>
                                            <a className="default__button default__button--3" href="tel:+8801729277769"><i
                                                className="icofont-phone"></i> +৮৮০ ১৭২৯ ২৭ ৭৭ ৬৯</a>
                                        </div>


                                    </div>


                                    <Follow></Follow>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer></Footer>
            </main>
        </div>
    )
}

export default StudioArt
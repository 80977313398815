import React from "react";
import DarkLight from "../components/DarkLight";
import Topbar from "../components/Topbar";
import Header from "../components/Header";
import Mobile from "../components/Mobile";
import About from "../components/About";
import Program from "../components/Program";
import Footer from "../components/Footer";
import Breadcrumbar from "../components/Breadcrumbar";

function Aboutus() {
    return (
        <div>

            <main className="main_wrapper overflow-hidden">
                <Topbar></Topbar>
                <Header></Header>
                <Mobile></Mobile>
                <div>
                    <div className="theme__shadow__circle"></div>
                    <div className="theme__shadow__circle shadow__right"></div>
                </div>
                <Breadcrumbar title='আমাদের সম্পর্কে'></Breadcrumbar>
                <About></About>
                <Program></Program>
                <Footer></Footer>
            </main>
        </div>
    )
}

export default Aboutus
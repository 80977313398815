import React from "react";
import Topbar from "../components/Topbar";
import Header from "../components/Header";
import Mobile from "../components/Mobile";
import Footer from "../components/Footer";
import {Link} from "react-router-dom";

function Ukulele(){
    return (
        <div>
            <main className="main_wrapper overflow-hidden">
                <Topbar></Topbar>
                <Header></Header>
                <Mobile></Mobile>
                <div>
                    <div className="theme__shadow__circle"></div>
                    <div className="theme__shadow__circle shadow__right"></div>
                </div>
                <div className="breadcrumbarea breadcrumbarea--2">

                    <div className="container">
                        <div className="row">
                            <div className="col-xl-8">
                                <div className="breadcrumb__content__wraper" data-aos="fade-up">
                                    <div className="breadcrumb__inner text-start">
                                        <ul>
                                            <li><Link to="/"> হোম </Link></li>
                                            <li> ইউকুলেলে কোর্স</li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="course__details__top--2">
                                    <div className="course__details__heading" data-aos="fade-up">
                                        <h3> ইউকুলেলে কোর্স </h3>
                                    </div>
                                    <div className="course__details__price" data-aos="fade-up">
                                        <ul>
                                            <li>
                                                <div className="course__details__date">
                                                    <i className="icofont-book-alt"></i> সপ্তাহে ১ টি ক্লাশ
                                                </div>

                                            </li>

                                            <li>
                                                <div className="course__date">
                                                    <i className="icofont-clock-time"></i> প্রতিটি ক্লাশ <span> ০১ঃ৩০ মিনিট </span>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="shape__icon__2">
                        <img loading="lazy" className=" shape__icon__img shape__icon__img__1"
                             src="img/herobanner/herobanner__1.png" alt="photo"/>
                        <img loading="lazy" className=" shape__icon__img shape__icon__img__2"
                             src="img/herobanner/herobanner__2.png" alt="photo"/>
                        <img loading="lazy" className=" shape__icon__img shape__icon__img__3"
                             src="img/herobanner/herobanner__3.png" alt="photo"/>
                        <img loading="lazy" className=" shape__icon__img shape__icon__img__4"
                             src="img/herobanner/herobanner__5.png" alt="photo"/>
                    </div>

                </div>
                <div className="blogarea__2 sp_top_50 sp_bottom_100">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12 col-lg-12">
                                <div className="row">
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12" data-aos="fade-up">
                                        <div className="pricingarea__content__wraper">
                                            <div className="pricingarea__heading">
                                                <div className="pricingarea__plan__type">
                                                    <h6>৩ মাস মেয়াদি কোর্স</h6>
                                                </div>

                                                <div className="pricingarea__number">
                                                    <h1><span className="price__currency">৳</span>১,০০০<span
                                                        className="price__durition">/ মাস</span></h1>
                                                </div>
                                            </div>
                                            <div className="pricingarea__list">
                                                <ul>
                                                    <li>
                                                        <i className="icofont-check"></i> Ukulele পরিচিতি
                                                    </li>
                                                    <li>
                                                        <i className="icofont-check"></i> বেসিক কর্ড এবং স্ট্রামিং
                                                        প্যাটার্নস
                                                    </li>
                                                    <li>
                                                        <i className="icofont-check"></i> সহজ গান
                                                    </li>
                                                    <li>
                                                        <i className="icofont-check"></i> জ্যা অগ্রগতি
                                                    </li>
                                                    <li>
                                                        <i className="icofont-check"></i> ফিঙ্গারপিকিং বেসিক
                                                    </li>
                                                    <li>
                                                        <i className="icofont-check"></i> ছন্দ এবং সময়
                                                    </li>
                                                    <li>
                                                        <i className="icofont-check"></i> অন্যদের সঙ্গে বাজানো
                                                    </li>
                                                    <li>
                                                        <i className="icofont-check"></i> গানের পারফরম্যান্স
                                                    </li>
                                                    <li>
                                                        <i className="icofont-check"></i> দক্ষতা মূল্যায়ন
                                                    </li>

                                                </ul>
                                            </div>
                                            <div className="pricingarea__button">
                                                <Link className="default__button" to="/Contact">যোগাযোগ</Link>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12" data-aos="fade-up">
                                        <div className="pricingarea__content__wraper">
                                            <div className="pricingarea__heading">
                                                <div className="pricingarea__plan__type">
                                                    <h6>৬ মাস মেয়াদি কোর্স</h6>
                                                </div>

                                                <div className="pricingarea__number">
                                                    <h1><span className="price__currency">৳</span>১,০০০<span
                                                        className="price__durition">/ মাস</span></h1>
                                                </div>
                                            </div>
                                            <div className="pricingarea__list">
                                                <ul>
                                                    <li>
                                                        <i className="icofont-check"></i> Ukulele পরিচিতি
                                                    </li>
                                                    <li>
                                                        <i className="icofont-check"></i> প্রসারিত জ্যা
                                                    </li>
                                                    <li>
                                                        <i className="icofont-check"></i> এডভান্স স্ট্রামিং প্যাটার্নস
                                                    </li>
                                                    <li>
                                                        <i className="icofont-check"></i> মধ্যম মানের গান
                                                    </li>
                                                    <li>
                                                        <i className="icofont-check"></i> ফিঙ্গারস্টাইল কৌশল
                                                    </li>
                                                    <li>
                                                        <i className="icofont-check"></i> ইমপ্রোভাইজেশনের ভূমিকা
                                                    </li>
                                                    <li>
                                                        <i className="icofont-check"></i> মিউজিক থিওরি বেসিক
                                                    </li>
                                                    <li>
                                                        <i className="icofont-check"></i> গান রচনা ও আয়োজন
                                                    </li>
                                                    <li>
                                                        <i className="icofont-check"></i> দক্ষতা মূল্যায়ন
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="pricingarea__button">
                                                <Link className="default__button" to="/Contact">যোগাযোগ</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer></Footer>
            </main>
        </div>
    )
}

export default Ukulele